<template>
  <div class="dataTableMainWrapper overlayWrapper" v-bind="dataTableMainWrapperAttributes">
    <tableViewTable
            :currentTable="localTable"
            :search="search"
            :result="result"
            v-on="$listeners"
            :hidePaging="hidePaging"
    >
    </tableViewTable>

    <!-- <loadingOverlay
      v-if="typeof currentTable['loadingOverlay'] !='undefined' && currentTable['loadingOverlay']===true"
    ></loadingOverlay>-->
  </div>
</template>


<script>
  import tableViewTable from "@/commonComponents/tableViewTable.vue";
  //import loadingOverlay from "@/commonComponents/loadingOverlay.vue";
export default {
  components: { tableViewTable /*loadingOverlay*/ },

  computed: {
    localTable: function () {
      // console.log("table vie changed");
      return this.currentTable;
    },
    dataTableMainWrapperAttributes: function () {
      // console.log("table vie changed");
      let dataTableMainWrapperAttributes = {};
      if (
        typeof this.currentTable != "undefined" &&
        typeof this.currentTable.dataTableMainWrapperAttributes != "undefined"
      ) {
        dataTableMainWrapperAttributes = this.currentTable
          .dataTableMainWrapperAttributes;
      }
      return dataTableMainWrapperAttributes;
    },
  },
  props: {
    currentTable: { type: Object },
    search: String,
    result: Object,
    hidePaging: Boolean
  },
};
</script>